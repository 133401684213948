// feature
import { useState, useRef } from 'react'
import styled from 'styled-components'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig } from '@/utils/styleConfig'
import { Seo, LayoutContainer } from '@/utils/globalComponents'

// components
import Layout from '@/components/Layout'
import { CommonButton } from '@/components/CommonButton'
import { HeadingLevel1 } from '@/components/HeadingLevel1'

const googleFormId = {
  name: 'entry.1445533379',
  read: 'entry.1180160178',
  company: 'entry.222925388',
  tel: 'entry.1628389396',
  fax: 'entry.343099959',
  email: 'entry.371802907',
  contents: 'entry.967354072',
  kind: 'entry.1980222371',
}

const IndexPage = () => {
  const form = useRef<HTMLFormElement>(null)
  const [submitted, setSubmit] = useState(false)

  const onSubmit = event => {
    event.preventDefault()
    form.current.action =
      'https://docs.google.com/forms/u/0/d/e/1FAIpQLScYO941HPSSzI7kk8IHhrQYtXG7OdJq5QRuGZFQqtP-yPA0tA/formResponse'

    form.current.submit()
    setSubmit(true)
  }

  const onLoad = () => {
    if (submitted) {
      window.location.href = '/contact/thanks/'
    }
  }

  return (
    <Layout marginBottom={0}>
      <Seo page="contact" />
      <HeadingContainer>
        <HeadingLevel1>お問い合わせ</HeadingLevel1>
      </HeadingContainer>
      <LayoutContainer bgColor="attention">
        <FormContainer>
          <form onSubmit={onSubmit} method="casePage" ref={form} target="invisibleIframe">
            <InputList>
              <li>
                <Label htmlFor="name">
                  名前
                  <LabelTag required />
                </Label>
                <Input
                  type="text"
                  id="name"
                  autoComplete="name"
                  name={googleFormId.name}
                  required
                />
              </li>
              <li>
                <Label htmlFor="read">
                  ふりがな
                  <LabelTag />
                </Label>
                <Input type="text" id="read" name={googleFormId.read} />
              </li>
              <li>
                <Label htmlFor="company">
                  会社名
                  <LabelTag />
                </Label>
                <Input type="text" id="company" name={googleFormId.company} />
              </li>
              <li>
                <Label htmlFor="tel">
                  電話番号
                  <LabelTag required />
                </Label>
                <Input type="text" id="tel" autoComplete="tel" name={googleFormId.tel} required />
              </li>
              <li>
                <Label htmlFor="fax">
                  FAX番号
                  <LabelTag />
                </Label>
                <Input type="text" id="fax" name={googleFormId.fax} />
              </li>
              <li>
                <Label htmlFor="email">
                  メールアドレス
                  <LabelTag required />
                </Label>
                <Input
                  type="email"
                  id="email"
                  autoComplete="email"
                  name={googleFormId.email}
                  required
                />
              </li>
              <li>
                <span>
                  お問い合わせ種別
                  <LabelTag />
                </span>
                <CategoryList>
                  <li>
                    <RadioButtonLabel>
                      <RadioButton
                        type="radio"
                        name={googleFormId.kind}
                        value="ディスプレイ事業について"
                      />
                      <CheckIcon />
                      ディスプレイ事業について
                    </RadioButtonLabel>
                  </li>
                  <li>
                    <RadioButtonLabel>
                      <RadioButton
                        type="radio"
                        name={googleFormId.kind}
                        value="情報機器事業について"
                      />
                      <CheckIcon />
                      情報機器事業について
                    </RadioButtonLabel>
                  </li>
                  <li>
                    <RadioButtonLabel>
                      <RadioButton type="radio" name={googleFormId.kind} value="新規事業について" />
                      <CheckIcon />
                      新規事業について
                    </RadioButtonLabel>
                  </li>
                  <li>
                    <RadioButtonLabel>
                      <RadioButton type="radio" name={googleFormId.kind} value="採用について" />
                      <CheckIcon />
                      採用について
                    </RadioButtonLabel>
                  </li>
                  <li>
                    <RadioButtonLabel>
                      <RadioButton type="radio" name={googleFormId.kind} value="その他" />
                      <CheckIcon />
                      その他
                    </RadioButtonLabel>
                  </li>
                </CategoryList>
              </li>
              <li>
                <Label htmlFor="contents">
                  お問い合わせ内容
                  <LabelTag required />
                </Label>
                <TextBox id="contents" name={googleFormId.contents} rows={5} required />
              </li>
            </InputList>
            <CommonButtonContainer>
              <CommonButton label="送信" type="submit" color="theme" />
            </CommonButtonContainer>
          </form>
        </FormContainer>
      </LayoutContainer>
      <InvisibleIframe name="invisibleIframe" onLoad={onLoad} />
    </Layout>
  )
}

const HeadingContainer = styled.div`
  text-align: center;
  padding-bottom: 4rem;
`

const FormContainer = styled.div`
  padding: 8rem 0;
  max-width: 75rem;
  margin: auto;
`

const InputList = styled.ul`
  > li {
    display: flex;
    justify-content: flex-end;
    font-size: 1.6rem;

    ${breakpointDown('sm')} {
      flex-direction: column;
    }

    + li {
      margin-top: 3rem;
    }
  }
`

const Label = styled.label`
  flex-shrink: 0;

  ${breakpointUp('md')} {
    line-height: 1.4;
  }
`

const LabelTag = styled.span<{ required?: boolean }>`
  &::before {
    content: '${props => (props.required ? '必須' : '任意')}';
    background-color: ${props => (props.required ? 'red' : 'gray')};
    padding: 0.4rem 0.6rem;
    margin-left: 1rem;
    border-radius: 0.4rem;
    width: 100%;
    height: 100%;
    color: ${colorConfig.textSubColor};
    font-size: 1.2rem;
    font-weight: bold;
  }
`

const CategoryList = styled.ul`
  ${breakpointUp('md')} {
    margin-left: 2rem;
    width: 70%;
  }
`

const Input = styled.input`
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  background-color: ${colorConfig.bgColor};
  width: 100%;

  ${breakpointDown('sm')} {
    margin-top: 0.4rem;
  }

  ${breakpointUp('md')} {
    margin-left: 2rem;
    width: 70%;
  }
`

const RadioButtonLabel = styled.label`
  position: relative;
  cursor: pointer;
  padding-left: 3rem;
`

const RadioButton = styled.input`
  margin-right: 1rem;

  // NOTE: a11y reset css より
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked + span {
    background-color: ${colorConfig.themeColor};
    border: none;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      border-radius: 50%;
    }

    &::before {
      width: 1.4rem;
      height: 1.4rem;
      background-color: ${colorConfig.textSubColor};
    }

    &::after {
      width: 1rem;
      height: 1rem;
      background-color: ${colorConfig.themeColor};
    }
  }
`

const CheckIcon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: ${colorConfig.borderColor};
  width: 1.8rem;
  height: 1.8rem;
`

const CommonButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

const TextBox = styled.textarea`
  padding: 0.4rem 1.2rem;
  border-radius: 0.4rem;
  background-color: ${colorConfig.bgColor};

  ${breakpointUp('md')} {
    margin-left: 2rem;
    width: 70%;
  }
`

const InvisibleIframe = styled.iframe`
  display: none;
`

export default IndexPage
