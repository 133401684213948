import { VFC } from 'react'
import styled from 'styled-components'
import { HeadingLevel1Props } from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig } from '@/utils/styleConfig'

export const HeadingLevel1: VFC<HeadingLevel1Props> = ({ children, subHeading, note }) => (
  <StyledHeadingLevel1>
    {subHeading && <SubHeading>{subHeading}</SubHeading>}
    {children}
    {note && <Note>{note}</Note>}
  </StyledHeadingLevel1>
)

HeadingLevel1.defaultProps = {
  subHeading: '',
  note: '',
}

const StyledHeadingLevel1 = styled.h1`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.5;

  ${breakpointUp('md')} {
    font-size: 4.8rem;
  }
`

const SubHeading = styled.span`
  margin-bottom: 0.8rem;
  line-height: 1.5;
  font-size: 1.4rem;

  ${breakpointUp('md')} {
    font-size: 2rem;
  }
`

const Note = styled.span`
  font-size: 1rem;
  line-height: 1.5;
  color: ${colorConfig.themeColor};
  text-transform: uppercase;
  letter-spacing: 0.04em;

  ${breakpointDown('sm')} {
    margin-top: 1rem;
  }

  ${breakpointUp('md')} {
    font-size: 1.4rem;
  }
`
